import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {
  Grommet, Box, Heading,
} from 'grommet';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import slugify from 'react-slugify';
import vitorTema from './theme';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Depoimentos = () => {
  const depoimentos = useStaticQuery(
    graphql`
    {
      wordpressPage(slug: { eq: "home" }) {
        title
        acf {
          depoimento_imagem {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1280, maxHeight: 720) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          depoimentos {
            titulo
            subtitulo
            texto
          }
        }
      }
    }     
    `,
  );

  const sliderComponent = React.createRef();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerPadding: '0',
  };


  function next() {
    const slider = sliderComponent.current;
    slider.slickNext();
  }

  function previous() {
    const slider = sliderComponent.current;
    slider.slickPrev();
  }
  return (
    <Grommet theme={vitorTema}>
      <div className="o-container">
        <div className="o-container--grid-12">
          <Box className="u-center-10" id="depoimentos">
            <Img fluid={depoimentos.wordpressPage.acf.depoimento_imagem.localFile.childImageSharp.fluid} />
          </Box>
          <Heading margin="none" className="u-center-10 u-decorated-header u-mb-s2 u-mt-s2">
            Depoimentos
        </Heading>
        </div>
        <div className="o-container--grid-12 u-mb-s2">
          <Box
            alignContent="center"
            justify="center"
            className="prev-next-depoimentos"
          >
            <a className="prev-slider" onClick={previous}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.858"
                height="30"
                viewBox="0 0 15.858 30"
              >
                <path
                  id="Seta"
                  d="M23.793,29.681a.847.847,0,0,0,.6.252.821.821,0,0,0,.6-.252L39.129,15.543a.84.84,0,0,0,0-1.2L24.992.2a.848.848,0,1,0-1.2,1.2L37.33,14.943,23.793,28.482a.845.845,0,0,0,0,1.2Z"
                  transform="translate(39.381 29.933) rotate(180)"
                  fill="#e9191f"
                />
              </svg>
            </a>
          </Box>
          <Box className="u-center-10">
            <Slider ref={sliderComponent} {...settings}>
              {depoimentos.wordpressPage.acf.depoimentos.map((depoimento, i) => (
                <Box key={slugify(i)}>
                  <div className="o-container--grid-10">
                    <div
                      className="u-span-8 depoimento-text"
                      dangerouslySetInnerHTML={{ __html: depoimento.texto }}
                    />
                    <div className="u-span-2 depoimento-autor">
                      <p className="u-main-color depoimento-autor__title">
                        {depoimento.titulo}
                      </p>
                      <p>{depoimento.subtitulo}</p>
                    </div>
                  </div>
                </Box>
              ))}
            </Slider>
          </Box>
          <Box
            alignContent="center"
            justify="center"
            className="prev-next-depoimentos"
          >
            <a className="next-slider" onClick={next}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.858"
                height="30"
                viewBox="0 0 15.858 30"
              >
                <path
                  id="Seta"
                  d="M23.793,29.681a.847.847,0,0,0,.6.252.821.821,0,0,0,.6-.252L39.129,15.543a.84.84,0,0,0,0-1.2L24.992.2a.848.848,0,1,0-1.2,1.2L37.33,14.943,23.793,28.482a.845.845,0,0,0,0,1.2Z"
                  transform="translate(39.381 29.933) rotate(180)"
                  fill="#e9191f"
                />
              </svg>
            </a>
          </Box>
        </div>
      </div>
    </Grommet>
  );
};

export default Depoimentos;
