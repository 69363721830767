import React from 'react';
import { graphql, Link } from 'gatsby';
import {
  Box, Grommet, Image, Heading, Button,
} from 'grommet';
import { Link as LocalLink } from 'react-scroll';
import Img from 'gatsby-image';
import { Helmet } from 'react-helmet';
import Contato from '../components/contato';
import Depoimentos from '../components/depoimentos';
import Layout from '../components/layout';

import vitorTema from '../components/theme';
import useSiteMetadata from '../components/use-site-metadata';

const PageTemplate = (props) => {
  // function changeHash(e) {
  //   window.location.hash = e.target.value
  // }

  const { title } = useSiteMetadata();
  const currentPage = props.data.wordpressPage;
  const atendimentos = props.data.allWordpressWpAtendimento;
  return (
    <Layout isHome>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      <Grommet theme={vitorTema}>
        <div id="sobre" name="sobre">
          <Box className="slider">
            <Image
              fit="cover"
              src={
                currentPage.featured_media.localFile.childImageSharp.fluid.src
              }
            />
            <Box className="slider-title">
              <Box
                align="center"
                dangerouslySetInnerHTML={{
                  __html: currentPage.acf.icone.content,
                }}
              />
              <h1
                dangerouslySetInnerHTML={{
                  __html: currentPage.acf.titulo,
                }}
                className="c-slider__title"
              />
              <h2
                dangerouslySetInnerHTML={{
                  __html: currentPage.acf.subtitulo,
                }}
                className="c-slider__subtitle"
              />
            </Box>
          </Box>
          <div className="c-sobre">
            <Img
              fluid={
                currentPage.acf.sobre_imagem.localFile.childImageSharp.fluid
              }
              className="is-mobile-only u-mt-s2"
            />
            <div className="o-container">
              <div className="o-container--grid-12">
                <Box className="u-center-10">
                  <Heading margin="none" className="u-decorated-header u-mt-s2">
                    Sobre
                  </Heading>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: currentPage.acf.sobre_primeiro_paragrafo,
                    }}
                    className="c-sobre__jornada u-bigger-text"
                  />
                  <Img
                    fluid={
                      currentPage.acf.sobre_imagem.localFile.childImageSharp.fluid
                    }
                    className="is-mobile-invisible u-mt-s2 u-mb-s2"
                  />
                  <div className="o-container--grid-10  u-mb-s2">
                    <div
                      className="u-span-6 s-wp-text"
                      dangerouslySetInnerHTML={{
                        __html: currentPage.acf.sobre_restante,
                      }}
                    />
                    <div className="u-span-3">
                      <Box className="c-call-to-action">
                        <h1>
                          Está precisando se conhecer? Vai ser um prazer
                          conversar com você
                        </h1>
                        <div className="is-mobile-invisible">
                          <LocalLink
                            activeClass="active"
                            to="como-funciona"
                            spy
                            hashSpy
                            smooth
                            duration={500}
                          >
                            <Button
                              label="Como funciona"
                              primary
                              // onClick={changeHash}
                              value="#como-funciona"
                            />
                          </LocalLink>
                        </div>
                      </Box>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </div>
        <div id="como-funciona" name="como-funciona" className="como-funciona">
          <div>
            <Img
              fluid={
                currentPage.acf.como_funciona_imagem.localFile.childImageSharp
                  .fluid
              }
            />
          </div>
          <div className="o-container">
            <div className="o-container--grid-12">
              <div className="u-center-10">
                <Heading
                  margin="none"
                  className="u-mt-s1 u-mb-s1 u-decorated-header"
                >
                  Como funciona
                </Heading>
                <div className="o-container--grid-2 u-mb-s1">
                  <div
                    className="u-bigger-text"
                    dangerouslySetInnerHTML={{
                      __html: currentPage.acf.como_funciona_intro,
                    }}
                  />
                </div>
                <div className="o-container--grid-2 como-funciona__itens s-wp-text">
                  {currentPage.acf.como_funciona_items.map(
                    (como_funciona, i) => (
                      <div key={i}>
                        <Heading
                          margin="none"
                          level="3"
                          className="u-main-color"
                        >
                          {como_funciona.titulo}
                        </Heading>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: como_funciona.texto,
                          }}
                        />
                      </div>
                    ),
                  )}
                </div>
                <Box className="u-mb-s2 u-mt-s2 is-mobile-invisible">
                  <LocalLink
                    activeClass="active"
                    to="o-que-eu-trato"
                    spy
                    hashSpy
                    smooth
                    duration={500}
                  >
                    <Button
                      label="o que eu trato"
                      primary
                      // onClick={changeHash}
                      value="#o-que-eu-trato"
                    />
                  </LocalLink>
                </Box>
              </div>
            </div>
          </div>
        </div>
        <div id="o-que-eu-trato" name="o-que-eu-trato" className="c-tratamento">
          <div>
            <Img
              fluid={
                currentPage.acf.o_que_eu_trato_imagem.localFile.childImageSharp
                  .fluid
              }
            />
          </div>
          <div className="o-container">
            <Heading
              margin="none"
              className="u-decorated-header u-mb-s1 u-center-desktop"
            >
              O que eu trato
            </Heading>
            <div className="o-container--grid-12">
              <div className="u-center-10 o-container--grid-tratamento">
                {atendimentos.edges.map((atendimento, i) => (
                  <Box className="box-tratamento" align="center" key={i}>
                    <Link to={`/atendimento/${atendimento.node.slug}`}>
                      <Box
                        align="center"
                        dangerouslySetInnerHTML={{
                          __html: atendimento.node.icone,
                        }}
                      />
                      <Heading
                        margin="none"
                        level="3"
                        className="u-main-color u-centralize c-tratamento__title"
                      >
                        {atendimento.node.title}
                      </Heading>
                    </Link>
                  </Box>
                ))}
              </div>
            </div>
            <Box align="center" className="u-mt-s2 u-mb-s2">
              <LocalLink
                activeClass="active"
                to="depoimentos"
                spy
                hashSpy
                smooth
                duration={500}
              >
                <Button
                  label="leia os depoimentos"
                  alignSelf="center"
                  primary
                  // onClick={changeHash}
                  value="#depoimentos"
                />
              </LocalLink>
            </Box>
          </div>
        </div>
        <Depoimentos
          id="depoimentos"
          name="depoimentos"
        />
        <div className="o-container"> <hr /></div>
        <Contato name="contato" id="contato" />
      </Grommet>
    </Layout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  {
    wordpressPage(slug: { eq: "home" }) {
      title
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 4000) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      date(formatString: "MMMM DD, YYYY")
      acf {
        icone {
          content
        }
        titulo
        subtitulo
        sobre_primeiro_paragrafo
        sobre_restante
        sobre_imagem {
          localFile {
            childImageSharp {
              fluid(maxWidth: 4000) {
                ...GatsbyImageSharpFluid_noBase64

              }
            }
          }
        }
        o_que_eu_trato_imagem {
          localFile {
            childImageSharp {
              fluid(maxWidth: 4000) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        como_funciona_intro
        como_funciona_items {
          titulo
          texto
        }
        como_funciona_imagem {
          localFile {
            childImageSharp {
              fluid(maxWidth: 4000) {
                ...GatsbyImageSharpFluid_noBase64
                src
              }
            }
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
    allWordpressWpAtendimento(sort: { fields: [menu_order] }) {
      edges {
        node {
          title
          icone
          slug
        }
      }
    }
  }
`;
